@import '~antd/dist/antd.css';

#responsive-canvas {
    width: 100%;
    margin-top: -15px;
  }

div[disabled] {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed !important;
}

h4 {
    color: #ececec;
    font-family: 'Inter', sans-serif;
}

.spinner {
    position: absolute;

}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.updatingAntMadeMeDoThis {
    margin-top: -12px;
    margin-bottom: 12px;
}
