@import '~antd/dist/antd.css';


.title {
    color: #ececec;
    font-family: 'Inter', sans-serif;
    font-size: calc(3.5em + 2vmin);
    margin-bottom: 20px;
    display: inline-block;
}

.icon-top {
    max-width:100%;
    height:auto;
    margin-bottom: -45px;
    margin-top: -40px;
}

.subtitle {
    color: #ececec;
    font-family: 'Inter', sans-serif;
    font-size: calc(1.4em + .8vmin);
    margin-bottom: .7em;
    margin-top: 10px;
}